button.accordion-button {
    outline: 0;
    border: 0;
    background: transparent;
    padding: 0;
    width: 100%;
    text-align: left;
}

h2.accordion-header{
    padding: 0;
    margin: 0;
   background-color: #5DAFF8;
    padding: 10px 10px;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    color: #000;
    text-decoration: none;
}
.accordion-item {
    margin-bottom: 15px;
    border: 1px solid #5DAFF8;
    border-radius: 15px;
    overflow: hidden;
    background-color: #ecf6ff;
}
.accordion-collapse {
    padding: 20px;
}






